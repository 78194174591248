import type { Motif } from '@snapchat/snap-design-system-marketing';
import {
  contrastBlackButtonVars,
  darkThemeHeaderVars,
  defaultMotif,
  FontFamily,
  m,
  mergeMotifs,
  MotifComponent,
  MotifScheme,
} from '@snapchat/snap-design-system-marketing';

/** Motif for the Pixy website. */
export const cheeriosMotif: Motif = mergeMotifs(defaultMotif, {
  name: 'Pixy',
  fontFamily: FontFamily.BUENOS_AIRES,
  [MotifScheme.DEFAULT]: {
    name: 'Default Pixy Scheme',
    [MotifComponent.ROOT]: {
      '--font-family': FontFamily.BUENOS_AIRES,
      '--text-desktop-font-line-height': '200%',
    },
  },
  [MotifScheme.SECONDARY]: {
    name: 'Pixy Transparent Background. Light Text.',
    [MotifComponent.ROOT]: {
      '--bg-color': m('--palette-plain-transparent'),
      '--fg-color': m('--neutral-v0'),
    },
    [MotifComponent.BUTTON]: contrastBlackButtonVars,
    [MotifComponent.HEADER]: darkThemeHeaderVars,
  },
});
